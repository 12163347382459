export default [
  {
    title: "Dashboard",
    route: "home",
    icon: "HomeIcon",
    action: "read",
    resource: "home",
  },
  {
    title: "Masters",
    route: "masters",
    icon: "GitlabIcon",
    action: "read",
    resource: "master",
  },
  {
    title: "Customers",
    icon: "UserIcon",
    route: "contacts",
    action: "read",
    resource: "contact",
  },
  {
    title: "Movies",
    route: "movies",
    icon: "VideoIcon",
    action: "read",
    resource: "movie",
  },
  {
    title: "Contracts",
    icon: "FileTextIcon",
    action: "read",
    resource: "contract",
    children: [
      {
        title: "Contracts List",
        route: "contracts",
        action: "read",
        resource: "contract",
      },
      {
        title: "Milestones",
        route: "milestones",
        action: "read",
        resource: "contract",
      },
      {
        title: "Bumpers",
        route: "bumpers",
        action: "read",
        resource: "contract",
      },
    ],
  },
  // {
  //   title: "Revenues & Expenses",
  //   icon: "TrendingUpIcon",
  //   action: "create",
  //   route: "AddRevenue",
  //   resource: "revenue",
  // },

  {
    title: "Revenues & Expenses",
    icon: "TrendingUpIcon",
    action: "create",
    route: "AddRevenueMain",
    resource: "revenue",
  },
  // {
  //   title: "Revenue Invoices",
  //   icon: "FileIcon",
  //   action: "create",
  //   route: "RevenueInvoice",
  //   resource: "revenue",
  // },

  {
    title: "Royalty Reports",
    route: "GenerateRoyalty",
    icon: "BarChart2Icon",
    action: "read",
    resource: "royalty_report",
  },

  {
    title: "Bookkeeping Reports",
    route: "Bookkeeping",
    icon: "FileIcon",
    action: "read",
    resource: "royalty_report",
  },

  // {
  //   title: "P&A Budgets",
  //   icon: "FolderIcon",
  //   route: "viewBudget",
  //   action: "read",
  //   resource: "budget_expense",
  // },

  // {
  //   title: "Reports",
  //   icon: "CheckSquareIcon",
  //   action: "read",
  //   resource: "reports",
  //   children: [
  //     {
  //       title: "Reporting Sales / Availabilities",
  //       route: "reporting",
  //       icon: "FolderPlusIcon",
  //       action: "read",
  //       resource: "reports",
  //     },
  //   ],
  // },
  {
    title: "Report Builder",
    icon: "FolderPlusIcon",
    action: "read",
    resource: "report_builder",
    children: [
      {
        title: "Pdf",
        route: "ReportBuilderPdf",
        icon: "FolderPlusIcon",
        action: "read",
        resource: "report_builder",
      },
      {
        title: "Excel",
        route: "ReportBuilderExcel",
        icon: "FolderPlusIcon",
        action: "read",
        resource: "report_builder",
      },
    ],
  },

  {
    title: "User Management",
    icon: "UserIcon",
    action: "read",
    resource: "user",
    children: [
      {
        title: "User",
        route: "User",
        icon: "UserIcon",
        action: "read",
        resource: "user",
      },
      {
        title: "Role",
        route: "Role",
        icon: "BriefcaseIcon",
        action: "read",
        resource: "user",
      },
    ],
  },
  {
    title: "Organization",
    route: "Organization",
    icon: "BriefcaseIcon",
    action: "read",
    resource: "organization",
  },
  {
    title: "Notifications",
    route: "Notifications",
    icon: "BellIcon",
    action: "read",
    resource: "home",
  },
];
