import Api from "./Api";

export const getAllMovie = async ({
  limit,
  page,
  search,
  archived,
  sales,
  vendor_id,
  sort,
  draft,
} = {}) =>
  Api().get(`movie/all`, {
    params: { limit, page, search, archived, sales, vendor_id, sort, draft },
  });

export const getSingleMovie = async (id) => Api().get(`movie/single/${id}`);

export const saveMovie = async (payload) => Api().post(`movie/save`, payload);

export const saveMovieWebsiteVisible = async (payload) => Api().post(`movie/website-visible`, payload);

export const getNewMovieUnique = async (payload) =>
  Api().post(`movie/movie-unique-id`, payload);

export const archiveMovie = async (movie_id, payload) =>
  Api().post(`movie/archive/${movie_id}`, payload);

export const unArchiveMovie = async (movie_id, payload) =>
  Api().post(`movie/un-archive/${movie_id}`, payload);

export const getMovieLogs = async (id) => Api().get(`movie/logs/${id}`);
