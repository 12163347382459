<template>
  <div>
    <!-- <b-button
      variant="primary"
      class="btn-icon mr-1"
      v-b-tooltip.hover
      title="Help"
      v-b-modal.modal-scrollable
    > -->

    <!-- <b-button
       
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="gradient-primary"
        class="btn-icon mr-1"
        v-b-tooltip.hover
        title="Enter FullScreen"
      > -->

    <!-- </b-button> -->
    <feather-icon
      style="margin-right: 8px"
      v-b-modal.modal-scrollable
      @click="handleEditable"
      icon="HelpCircleIcon"
      size="24"
    />
    <feather-icon
      style="cursor: pointer"
      @click="toggleFullScreen"
      size="22"
      icon="MaximizeIcon"
    />
    <!-- </b-button> -->
    <b-modal
      id="modal-scrollable"
      scrollable
      title="How it works?"
      size="xl"
      @ok="resetItems"
      @close="resetItems"
      ok-only
    >
      <div class="d-flex justify-content-end" v-if="allowEdit">
        <b-button
          size="sm"
          v-if="!showEditor"
          @click="showEditor = true"
          variant="primary"
          class="btn-icon mr-1 mb-1"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          size="sm"
          v-if="showEditor"
          @click="showEditor = false"
          variant="primary"
          class="btn-icon mr-1 mb-1"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <div
        class="d-flex flex-column align-items-center"
        v-if="showEditor && allowEdit"
      >
        <quill-editor
          v-model="description"
          :options="{ theme: 'snow' }"
          style="height: 400px"
          class="pb-5 w-100"
        />
        <button class="btn btn-primary mb-2" @click="handleSubmit">
          Save Changes
        </button>
      </div>

      <div v-if="!showEditor">
        <!-- <h3 v-if="allowEdit && description != ''">Preview</h3> -->
        <b-card border-variant="primary">
          <b-card-text>
            <div v-html="description"></div>
          </b-card-text>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, VBTooltip, BCard, BCardText } from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import store from "@/store";
import {
  saveHelpDescription,
  getHelpDescription,
} from "../../../apiServices/MastersServices";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      help_id: null,
      allowEdit: false,
      description: "",
      currentPath: "",
      showEditor: false,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    user_details() {
      return store.getters["user/getUserDetails"];
    },
  },
  components: {
    BButton,
    quillEditor,
    BCard,
    BCardText,
  },
  methods: {
    toggleFullScreen() {
      // If the document is not currently in fullscreen mode, request fullscreen
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      }
      // Otherwise, exit fullscreen mode
      else {
        document.exitFullscreen();
      }
    },
    resetItems() {
      this.help_id = null;
      this.allowEdit = false;
      this.description = "";
      this.currentPath = this.$route.path;
      this.showEditor = false;
    },
    async getHelp() {
      this.currentPath = this.$route.path;
      try {
        const response = await getHelpDescription({
          path: this.currentPath,
        });

        if (response.data.status) {
          this.description = response.data.data.description;
          this.help_id = response.data.data.help_id;
        }

        if (this.description === "") {
          this.showEditor = true;
        }
      } catch (err) {
        console.log("Error => ", err.message);
      }
    },

    handleEditable() {
      this.getHelp();
      if (this.user_details) {
        const user_type = this.user_details.role_name;
        console.log({ user_type });

        if (user_type == "Super Admin") {
          this.allowEdit = true;
        }
      }
    },

    async handleSubmit() {
      try {
        const response = await saveHelpDescription({
          description: this.description,
          path: this.currentPath,
          help_id: this.help_id,
        });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Help Description Updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.showEditor = false;
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Failed to Add Help Description",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error => ", err.message);
      }
    },
  },
  beforeMount() {
    // this.getHelp();
  },
};
</script>
