import { ref, watch } from '@vue/composition-api'
import { getAllMovie } from "@/apiServices/MovieServices"
import { getAllVendor } from '@/apiServices/VendorServices'

export default function useAutoSuggest(props) {

  const filteredData = ref({})

  /**
   * Filter group against provided query
   * Grp Structure:
   * {
   *    key: 'title',
   *    data: [
   *      title: 'Admin', img: 'someImage.png',
   *      title: 'Template', img: 'otherImage.png',
   *    ]
   * }
   * @param {Object} grp Group object to perform filter on
   * @param {String} query Query string to filter
   */
  const filterGrp = (grp, query) => {
    const exactEle = grp.data.filter(item => item[grp.key].toLowerCase().startsWith(query.toLowerCase()))
    const containEle = grp.data.filter(
      // prettier-ignore
      item => !item[grp.key].toLowerCase().startsWith(query.toLowerCase()) && item[grp.key].toLowerCase().indexOf(query.toLowerCase()) > -1,
    )
    return exactEle.concat(containEle).slice(0, props.searchLimit)
  }

  const searchQuery = ref('')
  const resetsearchQuery = () => {
    searchQuery.value = ''
  }

  const handleSearchQueryUpdate = val => {
    if (val === '') {
      filteredData.value = {}
    } else {
      const queriedData = {}
      const dataGrps = Object.keys(props.data)

      dataGrps.forEach((grp, i) => {
        queriedData[dataGrps[i]] = filterGrp(props.data[grp], val)
      })

      filteredData.value = queriedData

      // Customers
      getAllVendor({ search: val, limit: 4 })
        .then((response) => {

          if (!response.data.status) return;

          const vendorArr = response.data.data.data.map((vendor) => ({
            id: vendor.vendor_id,
            company: vendor.company,
            email: vendor.email
          }))

          filteredData.value = {
            ...filteredData.value,
            customers: vendorArr
          }
        })

      // Movies
      getAllMovie({ search: val, limit: 4 })
        .then((response) => {

          if (!response.data.status) return;

          const movieArr = response.data.data.data.map((movie) => ({
            id: movie.movie_id,
            title: movie.title
          }))

          filteredData.value = {
            ...filteredData.value,
            movie: movieArr
          }
        })
    }
  }

  // Debounce Search
  var debounceSearch = null
  watch(searchQuery, val => {
    if (debounceSearch) clearTimeout(debounceSearch)
    debounceSearch = setTimeout(() => {
      handleSearchQueryUpdate(val)
    }, 1000)
  })

  return {
    searchQuery,
    resetsearchQuery,
    filteredData,
  }
}
